import React, { useEffect, useState } from 'react';
import MainLayout from '../../components/mainLayout';
import Search from 'antd/es/input/Search';
import { Button, Col, Modal, Row, Spin, Table, TableColumnsType, TablePaginationConfig, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { AnyObject } from 'antd/es/_util/type';
import { Link } from 'react-router-dom';
import { deleteTeacher, getTeachersTable, transformTeachersToTableData } from '../../services/teacher';

const TeachersPage: React.FC = () => {
    const [teachersData, setTeachersData] = useState([] as AnyObject[]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = useState(20);
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/teacher/list', breadcrumbName: 'Profesores' },
    ];
    const columns: TableColumnsType<AnyObject> = [
        { title: 'Nombre', dataIndex: 'name', key: 'name' },
        { title: 'Apellido', dataIndex: 'lastname', key: 'lastname' },
        { title: 'Dirección', dataIndex: 'address', key: 'address', responsive: ['md'], className: 'address-cell' },
        { title: 'Teléfono móvil', dataIndex: 'cellphone', key: 'cellphone', responsive: ['md'] },
        { title: 'Teléfono fijo', dataIndex: 'phone', key: 'phone', responsive: ['md'] },
        { title: 'Correo', dataIndex: 'email', key: 'email', responsive: ['md'] },
        { title: 'Especialiad', dataIndex: 'speciality', key: 'speciality', responsive: ['md'] },
        {
            title: 'Acciones',
            key: 'actions',
            render: (prop) => (
                <span>
                    <Tooltip title="Editar Profesor">
                        <Link to={`/users/teacher/edit/${prop.actions.teacherId}`}>
                            <Button style={{ marginRight: 16 }} icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Eliminar Profesor">
                        <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => {
                            Modal.confirm({
                                title: '¿Estás seguro de que quieres eliminar este profesor?',
                                content: 'Esta acción no se puede deshacer.',
                                okText: 'Sí',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk() {
                                    handleDeleteTeacher(prop.actions.teacherId);
                                },
                            });
                        }} />
                    </Tooltip>
                </span>
            ),
            responsive: ['md']
        }
    ];
    const handleSearch = (value: string) => {
        setSearchText(value);
    };
    const handleDeleteTeacher = async (teacherId: string) => {
        try {
            setLoading(true);
            const response = await deleteTeacher(teacherId);
            if (response?.message === 'ok') {
                await loadTeachers();
            }
            if (response?.error && (response.error === 'clases asociadas' )) {
                Modal.error({
                    title: 'No se puede eliminar este profesor',
                    content: 'Hay clases asociados a este profesor. Elimina las clases primero.',
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || 1);
        setPageSize(pagination.pageSize || 20);
        setLoading(true);
    };
    const loadTeachers = async () => {
        try {
            setLoading(true);
            const { teachers, total } = await getTeachersTable({ offset: (currentPage - 1) * pageSize, limit: pageSize, search: searchText });
            setTeachersData(transformTeachersToTableData(teachers));
            setTotal(total);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadTeachers();
    }, [currentPage, pageSize, searchText]);

    return (
        <MainLayout activeItem="teachers" breadcrumbItems={breadcrumbRoutes}>
            <Row gutter={[16, 16]} className="w-full flex justify-end items-center">
                <Col xs={24} md={14} lg={10} xl={10} className="flex">
                    <Link to="/users/teacher/create">
                        <Button type="primary" icon={<PlusOutlined />}>Crear profesor</Button>
                    </Link>
                    <Search placeholder="Buscar profesor" onSearch={handleSearch} style={{ marginBottom: 16, marginLeft: 16 }} />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="w-full">
                <Col xs={24}>
                    <div className="w-full">
                        <Spin spinning={loading}>
                            <Table
                                style={{ whiteSpace: 'pre' }}
                                columns={columns}
                                dataSource={teachersData}
                                pagination={{ current: currentPage, pageSize, total }}
                                rowKey="_id"
                                onChange={handleTableChange}
                            />
                        </Spin>
                    </div>
                </Col>
            </Row>
        </MainLayout>
    );
};

export default TeachersPage;