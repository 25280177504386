import { apiCall } from './api';
import { Dayjs } from 'dayjs';

interface IResponseTotal {
    total: number;
}

interface IIncomeReport {
    _id: string;
    student: string;
    paymentMethod: string;
    amount: number;
    date: Date;
}

export const getActiveStudentsReport = async (): Promise<IResponseTotal> => {
    try {
        return await apiCall<IResponseTotal>({ url: '/report/active-students', method: 'GET', data: null });
    } catch (error) {
        return { total: 0 };
    }
};

export const getTotalIncomeReport = async (): Promise<IResponseTotal> => {
    try {
        return await apiCall<IResponseTotal>({ url: '/report/total-income', method: 'GET', data: null });
    } catch (error) {
        return { total: 0 };
    }
};

export const getTotalDebtReport = async (): Promise<IResponseTotal> => {
    try {
        return await apiCall<IResponseTotal>({ url: '/report/total-debt', method: 'GET', data: null });
    } catch (error) {
        return { total: 0 };
    }
};

export const getIncomeReport = async (startDate: Dayjs, endDate: Dayjs): Promise<IIncomeReport[]> => {
    return await apiCall<IIncomeReport[]>({ url: '/report/income', method: 'POST', data: { startDate, endDate } });
};