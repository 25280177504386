import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../components/mainLayout';
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Switch, Divider, DatePicker, Card, Tag, Tooltip, Alert } from 'antd';
import { PlusOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { IStudent, getStudents } from '../../services/student';
import { ITeacher, getTeachers } from '../../services/teacher';
import { ICourse, getCourses, getUniqueStudentsPerDate } from '../../services/course';
import { IClassCreate, IClassCreateResponse, createClass } from '../../services/class';
import { IPaymentCreate, createPayment } from '../../services/payment';
import { RuleObject } from 'antd/es/form';
import { PAYMENT_TYPE } from '../../utils/constants';
import ClassSummary, { IClassSummary } from './components/classSummary';
import { useAuth } from '../../contexts/authContext';

interface IPayment {
    paymentType: string;
    paymentValue: number;
    paymentDate: Date;
    operation: string;
}
interface FormValues {
    student: string;
    course: string;
    teacher: string;
    classDates: { date: Date, occupiedPlaces: number, students: string[] }[];
    duration: number;
    paymentPerClass: number;
    payTeacher: boolean;
    status: boolean;
    notifyByEmail: boolean;
    description: string;
    type: string;
    enrollment: boolean;
    total: number;
    debt: boolean;
    discountEnabled: boolean;
    discountType: string;
    discountValue: number;
    expiration_alert: boolean;
    payments: IPayment[];
}

const CreateClassPage: React.FC = () => {
    const [form] = Form.useForm();
    const { session } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loadingOccupiedPlaces, setLoadingOccupiedPlaces] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState("");
    const [students, setStudents] = useState<IStudent[]>([]);
    const [courses, setCourses] = useState<ICourse[]>([]);
    const [teachers, setTeachers] = useState<ITeacher[]>([]);
    const [discountEnabled, setDiscountEnabled] = useState(false);
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/class/list', breadcrumbName: 'Clases' },
        { path: '/class/create', breadcrumbName: 'Crear clase' },
    ];

    const printReceipt = (otherValues: FormValues) => {
        const selectedStudent = students.find((student) => student._id === otherValues.student);
        const selectedTeacher = teachers.find((teacher) => teacher._id === otherValues.teacher);
        const studentName = `${selectedStudent?.nombre || ''} ${selectedStudent?.apellido || ''}`;
        const teacherName = `${selectedTeacher?.nombre || ''} ${selectedTeacher?.apellido || ''}`;
        const classData: IClassSummary = {
            courseName: courses.find((course) => course._id === otherValues.course)?.nombre || '',
            studentName,
            studentId: selectedStudent?.documento || '',
            teacher: teacherName,
            dates: otherValues.classDates.map((classDate) => new Date(classDate.date).toLocaleDateString() + ' ' + new Date(classDate.date).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: false })),
            amount: otherValues.total,
            discount: otherValues.discountValue,
            cashier: session?.username || '',
            type: otherValues.type,
        };
        const printWindow = window.open('', '_blank');
        if (!printWindow) return;
        const rootElement = printWindow.document.createElement('div');
        printWindow.document.body.appendChild(rootElement);
        ReactDOM.render(<ClassSummary classData={classData} session={session} />, rootElement);
        printWindow.print();
    };
    const onFinish = async (values: FormValues) => {
        const { classDates, ...otherValues } = values;
        const classData: Omit<IClassCreate, 'fecha'> = {
            alumno: otherValues.student,
            curso: otherValues.course,
            profesor: otherValues.teacher,
            vista: otherValues.status ?? false,
            duracion: otherValues.duration,
            pagar_profesor: otherValues.payTeacher ?? false,
            valor_profesor: otherValues.paymentPerClass,
            notificar: otherValues.notifyByEmail ?? false,
            descripcion: otherValues.description ?? '',
            tipo: otherValues.type,
        };

        try {
            setLoading(true);
            const promises = classDates.map((classDate) =>
                createClass({ ...classData, fecha: classDate.date })
            );
            const results = await Promise.all(promises);
            const okResults = results.filter((result) => result._id && result._id.trim() !== '');
            if (okResults.length === results.length) {
                const result = classData.tipo !== 'Demostrativa' ? await savePayment(results) : { message: 'ok' };
                if (result.message === 'ok') {
                    setMessage('Clase(s) y pago(s) creados correctamente.');
                    printReceipt(values);
                }
            } else {
                setMessage('Algunas clases no se pudieron crear correctamente.');
            }
        } catch (error) {
            console.error(error);
            setMessage('Ha ocurrido un error. Por favor, intenta de nuevo.');
        } finally {
            setLoading(false);
        }
        setIsModalVisible(true);
    };
    const savePayment = async (newClasses: IClassCreateResponse[]) => {
        const paymentData: Omit<IPaymentCreate, '_id' | 'institucion' | 'fecha_registro'> = {
            clases: newClasses.map((newClass) => newClass._id),
            alumno: newClasses[0].alumno,
            curso: newClasses[0].curso,
            tipo_clase: newClasses[0].tipo,
            descripcion: '',
            matricula: form.getFieldValue('enrollment') ?? false,
            alerta_vencimiento: form.getFieldValue('expiration_alert') ?? false,
            debe: form.getFieldValue('debt') ?? false,
            total: form.getFieldValue('total'),
            descuento: {
                tipo: form.getFieldValue('discountType'),
                valor: form.getFieldValue('discountValue'),
            },
            pagos: form.getFieldValue('payments').map((payment: IPayment) => ({
                codigo: Date.now(),
                tipo: payment.paymentType,
                valor: payment.paymentValue,
                fecha: payment.paymentDate,
                operacion: payment.operation,
            })),
        };
        return await createPayment(paymentData);
    };
    const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const validatePayments = (_rule: RuleObject, value: IPayment[]) => {
        if (form.getFieldValue('type') === 'Demostrativa') return Promise.resolve();
        if (!value || value?.length === 0) {
            return Promise.reject(new Error('Se requiere al menos un pago'));
        }
        const total = form.getFieldValue('total');
        const totalPayments = value.reduce((acc, cur) => {
            return acc + cur?.paymentValue ?? 0;
        }, 0);
        if (totalPayments > total) {
            return Promise.reject(new Error('La suma de los pagos supera el total'));
        }
        form.setFieldValue('debt', totalPayments < total);
        return Promise.resolve();
    };
    const validateClassDates = (_rule: RuleObject, value: Date[]) => {
        if (!value || value?.length === 0) {
            return Promise.reject(new Error('Se requiere al menos una fecha'));
        }
        return Promise.resolve();
    };
    const validateOccupiedPlaces = async () => {
        const course = form.getFieldValue('course');
        if (!course) return;
        try {
            setLoadingOccupiedPlaces(true);
            const classDates = form.getFieldValue('classDates')
                .filter((classDate: { date: string }) => classDate?.date)
                .map((classDate: { date: string }) => new Date(classDate.date).toISOString());
            const teacher = form.getFieldValue('teacher');
            const classType = form.getFieldValue('type');
            const occupiedPlacesResponse = await getUniqueStudentsPerDate(course, classDates, classType, teacher);
            const occupiedPlacesByDate = occupiedPlacesResponse.reduce((acc, curr) => {
                acc[curr._id] = { count: curr.count, alumnos: curr.alumnos };
                return acc;
            }, {} as any);

            const updatedClassDates = form.getFieldValue('classDates').map((classDate: { date: string }) => {
                return { ...classDate, occupiedPlaces: occupiedPlacesByDate[new Date(classDate.date).toISOString()]?.count || 0, students: occupiedPlacesByDate[new Date(classDate.date).toISOString()]?.alumnos || [] };
            });

            form.setFieldsValue({ classDates: updatedClassDates });
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingOccupiedPlaces(false);
        }
    };

    useEffect(() => {
        const init = async () => {
            const [students, teachers, courses] = await Promise.all([getStudents(), getTeachers(), getCourses()]);
            setStudents(students);
            setTeachers(teachers);
            setCourses(courses);
        };
        init();
        form.setFieldsValue({
            notifyByEmail: true,
            expiration_alert: true,
            paymentPerClass: 0
        });
    }, []);

    return (
        <MainLayout activeItem="class" breadcrumbItems={breadcrumbRoutes}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <Divider orientation="center">Información de la Clase</Divider>
                        <Form.Item name="student" label="Alumno" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                filterOption={filterOption}
                                options={students.map((student) => ({
                                    value: student._id,
                                    label: `${student.nombre} ${student.apellido}`,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item name="course" label="Curso" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                filterOption={filterOption}
                                options={courses.map((course) => ({
                                    value: course._id,
                                    label: `${course.nombre}`,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item name="teacher" label="Profesor" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                filterOption={filterOption}
                                options={teachers.map((teacher) => ({
                                    value: teacher._id,
                                    label: `${teacher.nombre} ${teacher.apellido}`,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item name="type" label="Tipo" rules={[{ required: true }]}>
                            <Select>
                                <Select.Option value="Escuela">Escuela</Select.Option>
                                <Select.Option value="Domicilio">Domicilio</Select.Option>
                                <Select.Option value="Virtual">Virtual</Select.Option>
                                <Select.Option value="Demostrativa">Demostrativa</Select.Option>
                            </Select>
                        </Form.Item>
                        <Card title="Fechas de clases" style={{ marginBottom: '15px' }} extra={<Tooltip title="Cargar alumnos registrados"><Button type='default' loading={loadingOccupiedPlaces} onClick={validateOccupiedPlaces} icon={<UserOutlined />} /></Tooltip>}>
                            <Form.List name="classDates" rules={[{ validator: validateClassDates }]}>
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field) => (
                                            <Row gutter={16} key={field.key} align='middle'>
                                                <Col span={16}>
                                                    <Form.Item
                                                        {...{ ...field, key: undefined }}
                                                        name={[field.name, 'date']}
                                                        rules={[{ required: true, message: 'Ingresa una fecha' }]}
                                                    >
                                                        <Input type='datetime-local' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={3}>
                                                    <Form.Item
                                                        {...{ ...field, key: undefined }}
                                                        name={[field.name, 'occupiedPlaces']}
                                                    >
                                                        <Tooltip
                                                            title={`${form.getFieldValue(['classDates', field.name, 'students'])?.map((student: { nombre: string; edad: number; }) => `${student.nombre} (${student.edad})`).join('\n') || 'Ninguno'}`}
                                                            overlayStyle={{ whiteSpace: 'pre-line' }}>
                                                            <Tag icon={<UserOutlined />} bordered={false} color="blue">{form.getFieldValue(['classDates', field.name, 'occupiedPlaces']) || '0'}</Tag>
                                                        </Tooltip>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4} style={{ marginBottom: 22 }}>
                                                    <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => remove(field.name)} />
                                                </Col>
                                            </Row>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Agregar fecha
                                            </Button>
                                            {errors.length > 0 && (<Form.ErrorList errors={errors} />)}
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <Divider orientation="center"><br /></Divider>
                        <Form.Item name="duration" label="Duración (horas)" rules={[{ required: true }]}>
                            <InputNumber min={0.1} step={0.1} />
                        </Form.Item>
                        <Form.Item name="status" label="Estado" valuePropName="checked">
                            <Switch checkedChildren="Vista" unCheckedChildren="No vista" />
                        </Form.Item>
                        <Form.Item name="payTeacher" label="Pagar al profesor?" valuePropName="checked">
                            <Switch checkedChildren="Si" unCheckedChildren="No" />
                        </Form.Item>
                        <Form.Item name="paymentPerClass" label="Pago por clase al profesor" rules={[{ required: true }]}>
                            <InputNumber prefix='S/' min={0} step={0.1} />
                        </Form.Item>
                        <Form.Item name="notifyByEmail" label="Notificar por correo" valuePropName="checked">
                            <Switch checkedChildren="Si" unCheckedChildren="No" />
                        </Form.Item>
                        <Form.Item name="description" label="Descripción">
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <Divider orientation="center">Información del pago</Divider>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item name="total" label="Total pago" rules={[{ required: true }]}>
                                    <InputNumber prefix='S/' min={0} step={0.1} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="enrollment" label="Matrícula" valuePropName="checked">
                                    <Switch checkedChildren="Si" unCheckedChildren="No" />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item name="expiration_alert" label="Alerta de vencimiento" valuePropName="checked">
                                    <Switch checkedChildren="Si" unCheckedChildren="No" />
                                </Form.Item>
                            </Col>
                            <Col hidden span={8}>
                                <Form.Item name="debt" label="Debe" valuePropName="checked">
                                    <Switch checkedChildren="Si" unCheckedChildren="No" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item name="discountEnabled" label="Descuento" valuePropName="checked">
                                    <Switch
                                        checkedChildren="Si"
                                        unCheckedChildren="No"
                                        onChange={(checked) => setDiscountEnabled(checked)}
                                    />
                                </Form.Item>
                            </Col>
                            {discountEnabled && (
                                <>
                                    <Col span={8}>
                                        <Form.Item name="discountType" label="Tipo de Descuento" rules={[{ required: true }]}>
                                            <Select>
                                                <Select.Option value="porcentaje">Porcentaje</Select.Option>
                                                <Select.Option value="valor">Valor</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item name="discountValue" label="Valor de Descuento" rules={[{ required: true }]}>
                                            <InputNumber min={0} step={0.1} />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Form.List name="payments" rules={[{ validator: validatePayments }]}>
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map(field => (
                                        <Card key={field.key} style={{ marginBottom: '15px' }}>
                                            <Row gutter={16}>
                                                <Col span={14}>
                                                    <Form.Item
                                                        {...{ ...field, key: undefined }}
                                                        name={[field.name, 'paymentType']}
                                                        rules={[{ required: true, message: 'Ingresa medio de pago' }]}
                                                    >
                                                        <Select placeholder="Medio de pago">
                                                            {Object.keys(PAYMENT_TYPE).map((key) => (
                                                                <Select.Option key={key} value={key}>
                                                                    {PAYMENT_TYPE[key]}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item
                                                        {...{ ...field, key: undefined }}
                                                        name={[field.name, 'paymentValue']}
                                                        rules={[{ required: true, message: 'Ingresa el valor' }]}
                                                    >
                                                        <InputNumber prefix='S/' placeholder="Valor" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => remove(field.name)} />
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <Form.Item
                                                        {...{ ...field, key: undefined }}
                                                        name={[field.name, 'paymentDate']}
                                                        rules={[{ required: true, message: 'Ingresa la fecha' }]}
                                                    >
                                                        <DatePicker placeholder="Fecha" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...{ ...field, key: undefined }}
                                                        name={[field.name, 'operation']}
                                                    >
                                                        <Input placeholder="Código de operación" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Agregar Pago
                                        </Button>
                                        {errors.length > 0 && (<Form.ErrorList errors={errors} />)}
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Form.Item style={{ textAlign: 'right' }}>
                            <Button loading={loading} type="primary" htmlType="submit">
                                Crear Clase(s)
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Modal
                title="Resultado"
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={() => {
                        setIsModalVisible(false);
                        form.resetFields();
                    }}>
                        Crear otra clase
                    </Button>,
                    <Button key="back" onClick={() => navigate('/class/list', { replace: true })}>
                        Volver al listado de clases
                    </Button>,
                ]}
            >
                <p>{message}</p>
            </Modal>
        </MainLayout>
    );
};

export default CreateClassPage;