import React from 'react';
import { Layout, Menu, Button, theme, Breadcrumb, Tooltip } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, DashboardOutlined, UserOutlined, BookOutlined, ReadOutlined, DollarOutlined, BarChartOutlined, LogoutOutlined } from '@ant-design/icons';
import type { BreadcrumbProps } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import { MenuProps } from 'antd/lib';

const { Header, Sider, Content } = Layout;

interface MainLayoutProps {
    children: React.ReactNode;
    activeItem: string;
    breadcrumbItems: BreadcrumbProps['items'];
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, activeItem, breadcrumbItems }) => {
    const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();
    const { setToken, sideMenuCollapsed, setSideMenuCollapsed, isAdmin } = useAuth();
    const sideItems: MenuProps['items'] = [
        {
            key: 'dashboard',
            icon: <DashboardOutlined />,
            label: <Link to="/">Dashboard</Link>,
        },
        {
            key: 'users',
            icon: <UserOutlined />,
            label: 'Usuarios',
            children: [
                {
                    key: 'students',
                    label: <Link to="/users/student/list">Alumnos</Link>,
                },
                {
                    key: 'teachers',
                    label: <Link to="/users/teacher/list">Profesores</Link>,
                },
                {
                    key: 'systemUsers',
                    label: isAdmin ? <Link to="/users/list">Usuarios</Link> : 'Usuarios',
                    disabled: !isAdmin
                },
            ],
        },
        {
            key: 'class',
            icon: <BookOutlined />,
            label: <Link to="/class/list">Clases</Link>
        },
        {
            key: 'courses',
            icon: <ReadOutlined />,
            label: <Link to="/courses/list">Cursos</Link>,
        },
        {
            key: 'payments',
            icon: <DollarOutlined />,
            label: <Link to="/payment/list">Pagos</Link>,
        },
        {
            key: 'reports',
            icon: <BarChartOutlined />,
            label: 'Reportes',
            children: [
                {
                    key: 'incomeReport',
                    label: isAdmin ? <Link to="/reports/income">Ingresos</Link> : 'Ingresos',
                    disabled: !isAdmin
                },
            ]
        },
    ];
    const logout = () => {
        localStorage.removeItem('token');
        setToken(null);
    };

    return (
        <Layout style={{ minHeight: '100vh', maxHeight: '100%' }}>
            <Sider trigger={null} collapsible collapsed={sideMenuCollapsed} style={{ background: colorBgContainer }}>
                <div className="demo-logo-vertical" />
                <Menu
                    mode="inline"
                    defaultSelectedKeys={[activeItem]}
                    items={sideItems}
                    style={{ cursor: 'pointer' }}
                />
            </Sider>
            <Layout>
                <Header style={{ padding: 0, background: colorBgContainer }}>
                    <Button
                        type="text"
                        icon={sideMenuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setSideMenuCollapsed(!sideMenuCollapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <Tooltip title="Cerrar sesión">
                        <Button
                            type="text"
                            icon={<LogoutOutlined />}
                            onClick={logout}
                            style={{
                                fontSize: '16px',
                                position: 'absolute',
                                right: 16,
                                top: 16,
                                color: 'red',
                            }}
                        />
                    </Tooltip>
                </Header>
                <Breadcrumb
                    style={{ margin: '10px 0px 5px 16px' }}
                    items={breadcrumbItems}
                    itemRender={(route, params, routes, paths) => {
                        const last = routes.indexOf(route) === routes.length - 1;
                        return last ? (
                            <span>{route.breadcrumbName}</span>
                        ) : (
                            <Link to={route.path ?? '/'}>{route.breadcrumbName}</Link>
                        );
                    }}
                />
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};

export default MainLayout;