import React, { useEffect, useState } from 'react';
import { Col, Row, Table, Input, TableColumnsType, TablePaginationConfig, Spin, Checkbox, Button, Tooltip, Modal, Tag } from 'antd';
import { EditOutlined, DeleteOutlined, DollarCircleOutlined, PlusOutlined } from '@ant-design/icons';
import MainLayout from '../../components/mainLayout';
import { AnyObject } from 'antd/es/_util/type';
import { deleteClass, getClasses, transformClassesToTableData } from '../../services/class';
import { CLASS_TYPE } from '../../utils/constants';
import { Link } from 'react-router-dom';

const { Search } = Input;

const ClassesPage: React.FC = () => {
    const [classTypes, setClassTypes] = useState(() => {
        const savedClassTypes = localStorage.getItem('classTypes');
        return savedClassTypes ? JSON.parse(savedClassTypes) : [CLASS_TYPE[0], CLASS_TYPE[1]];
    });
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [classesData, setClassesData] = useState([] as AnyObject[]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/class/list', breadcrumbName: 'Clases' },
    ];
    const columns: TableColumnsType<AnyObject> = [
        { title: 'Alumno', dataIndex: 'student', key: 'student' },
        { title: 'Curso', dataIndex: 'course', key: 'course' },
        { title: 'Profesor', dataIndex: 'teacher', key: 'teacher', responsive: ['md'] },
        { title: 'Tipo', dataIndex: 'type', key: 'type', responsive: ['md'] },
        { title: 'Duración (horas)', dataIndex: 'duration', key: 'duration', responsive: ['md'] },
        {
            title: 'Vista',
            dataIndex: 'view',
            key: 'view',
            responsive: ['md'],
            render: (view: boolean) => <Tag color={view ? 'green' : 'red'}>{view ? 'Vista' : 'No vista'}</Tag>
        },
        { title: 'Fecha', dataIndex: 'date', key: 'date', responsive: ['md'] },
        {
            title: 'Acciones',
            key: 'actions',
            render: (prop) => (
                <span>
                    <Tooltip title="Editar Clase">
                        <Link to={`/class/edit/${prop.actions.classId}`}>
                            <Button icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                    {prop.actions.paymentId ? (
                        <Tooltip title="Editar Pagos">
                            <Link to={`/payment/edit/${prop.actions.paymentId}`}>
                                <Button style={{ marginRight: 14, marginLeft: 14, backgroundColor: 'green', color: 'white' }} icon={<DollarCircleOutlined />} />
                            </Link>
                        </Tooltip>
                    ) : (
                        <Tooltip title="No hay un pago asociado">
                            <Button style={{ marginRight: 14, marginLeft: 14, backgroundColor: 'lightgrey', color: 'white' }} icon={<DollarCircleOutlined />} />
                        </Tooltip>
                    )}
                    <Tooltip title="Eliminar Clase">
                        <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => {
                            Modal.confirm({
                                title: '¿Estás seguro de que quieres eliminar esta clase?',
                                content: 'Esta acción no se puede deshacer.',
                                okText: 'Sí',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk() {
                                    handleDeleteClass(prop.actions.classId);
                                },
                            });
                        }} />
                    </Tooltip>
                </span>
            ),
            responsive: ['md']
        }
    ];

    const handleDeleteClass = async (classId: string) => {
        try {
            setLoading(true);
            const response = await deleteClass(classId);
            if (response?.message === 'ok') {
                await loadClasses();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    const handleSearch = (value: string) => {
        setSearchText(value);
    };
    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || 1);
        setPageSize(pagination.pageSize || 20);
        setLoading(true);
    };
    const handleClassTypeChange = (checkedValues: any) => {
        setClassTypes(checkedValues);
        localStorage.setItem('classTypes', JSON.stringify(checkedValues));
    };
    const loadClasses = async () => {
        try {
            setLoading(true);
            const { classes, total } = await getClasses({ offset: (currentPage - 1) * pageSize, limit: pageSize, search: searchText, classTypes });
            setClassesData(transformClassesToTableData(classes));
            setTotal(total);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadClasses();
    }, [currentPage, pageSize, searchText, classTypes]);

    return (
        <MainLayout activeItem="class" breadcrumbItems={breadcrumbRoutes}>
            <Row gutter={[16, 16]} className="w-full flex justify-between items-center">
                <Col xs={24} md={8} className="flex justify-start items-center">
                    <Checkbox.Group options={CLASS_TYPE} defaultValue={classTypes} onChange={handleClassTypeChange} />
                </Col>
                <Col xs={24} md={10} className="flex justify-end">
                    <Link to="/class/create">
                        <Button type="primary" icon={<PlusOutlined />}>Crear Clase</Button>
                    </Link>
                    <Search placeholder="Buscar clases" onSearch={handleSearch} style={{ marginBottom: 16, marginLeft: 16 }} />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="w-full">
                <Col xs={24}>
                    <div className="w-full">
                        <Spin spinning={loading}>
                            <Table
                                columns={columns}
                                dataSource={classesData}
                                pagination={{ current: currentPage, pageSize, total }}
                                rowKey="_id"
                                onChange={handleTableChange}
                            />
                        </Spin>
                    </div>
                </Col>
            </Row>
        </MainLayout>
    );
};

export default ClassesPage;