import mountain1 from '../../assets/images/backgrounds/mountains1.png';
import mountain2 from '../../assets/images/backgrounds/mountains2.jpg';
import mountain3 from '../../assets/images/backgrounds/mountains3.png';
import mountain4 from '../../assets/images/backgrounds/mountains4.png';
import mountain5 from '../../assets/images/backgrounds/mountains5.png';

export const images: {[key: string]: string} = {
    mountain1,
    mountain2,
    mountain3,
    mountain4,
    mountain5,
};