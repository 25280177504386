import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export interface ISession {
    userId: string;
    username: string;
    institute: {
        _id: string;
        email: string;
        facebook: string;
        pagina_web: string;
        tel_movil: string;
        tel_fijo: string;
        nombre: string;
        __v: number;
        avatar: string;
        recordatorios: {
            escuela: string;
            domicilio: string;
        };
        ubicacion: {
            direccion: string;
        };
    };
    type: string;
    status: string;
    iat: number;
}

interface AuthContextData {
    token: string | null;
    setToken: React.Dispatch<React.SetStateAction<string | null>>;
    sideMenuCollapsed: boolean;
    setSideMenuCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    isAdmin: boolean;
    session: ISession | null;
    setSession: React.Dispatch<React.SetStateAction<ISession | null>>;
}

const AuthContext = createContext<AuthContextData | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [token, setToken] = useState<string | null>(() => {
        const storedToken = localStorage.getItem('token');
        return storedToken ? storedToken : null;
    });
    const [sideMenuCollapsed, setSideMenuCollapsed] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [session, setSession] = useState<ISession | null>(null);
    
    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
            const decodedToken = jwtDecode(token) as ISession;
            setIsAdmin(decodedToken?.type === 'administrador');
            setSession(decodedToken);
        } else {
            localStorage.removeItem('token');
            setIsAdmin(false);
        }
    }, [token]);

    return (
        <AuthContext.Provider value={{
            token,
            setToken,
            sideMenuCollapsed,
            setSideMenuCollapsed,
            isAdmin,
            session,
            setSession
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextData => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};