import React, { useEffect, useState } from 'react';
import { Col, Row, Table, Input, TableColumnsType, TablePaginationConfig, Spin, Checkbox, Button, Tooltip, Modal, Tag } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import MainLayout from '../../components/mainLayout';
import { AnyObject } from 'antd/es/_util/type';
import { CLASS_TYPE } from '../../utils/constants';
import { Link } from 'react-router-dom';
import { deletePayment, getPayments, transformPaymentsToTableData } from '../../services/payment';
import { IClass } from '../../services/class';
import { formatDate } from '../../utils';

const { Search } = Input;

const PaymentsPage: React.FC = () => {
    const [classTypes, setClassTypes] = useState(() => {
        const savedClassTypes = localStorage.getItem('classTypes');
        return savedClassTypes ? JSON.parse(savedClassTypes) : [CLASS_TYPE[0], CLASS_TYPE[1]];
    });
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [paymentsData, setPaymentsData] = useState([] as AnyObject[]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/payment/list', breadcrumbName: 'Pagos' },
    ];
    const columns: TableColumnsType<AnyObject> = [
        { title: 'Alumno', dataIndex: 'student', key: 'student' },
        { title: 'Curso', dataIndex: 'course', key: 'course' },
        { title: 'Tipo', dataIndex: 'type', key: 'type', responsive: ['md'] },
        { title: 'Total', dataIndex: 'total', key: 'total', responsive: ['md'] },
        { title: 'Abonos', dataIndex: 'payments', key: 'payments', responsive: ['md'] },
        { title: 'Debe', dataIndex: 'debt', key: 'debt', responsive: ['md'], render: (debt: boolean) => <Tag color={debt ? 'red' : 'green'}>{debt ? 'Debe' : 'No debe'}</Tag> },
        {
            title: 'Fechas de clases', dataIndex: 'classes', key: 'classes', responsive: ['md'], render: (classes: IClass[]) => (
                <>
                    {classes.sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime())
                        .map((c, index) => (
                            <React.Fragment key={c._id}>
                                <Link style={{ cursor: 'pointer' }} to={`/class/edit/${c._id}`}>{formatDate(c.fecha)}</Link>
                                {index < classes.length - 1 && <br />}
                            </React.Fragment>
                        ))}
                </>
            )
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (prop) => (
                <span>
                    <Tooltip title="Editar Pago">
                        <Link to={`/payment/edit/${prop.actions.paymentId}`}>
                            <Button style={{ marginRight: 16 }} icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Eliminar Pago">
                        <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => {
                            Modal.confirm({
                                title: '¿Estás seguro de que quieres eliminar esta pago?',
                                content: 'Esto eliminará también todas las clases asociadas a este pago.',
                                okText: 'Sí',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk() {
                                    handleDeletePayment(prop.actions.paymentId);
                                },
                            });
                        }} />
                    </Tooltip>
                </span>
            ),
            responsive: ['md']
        }
    ];

    const handleDeletePayment = async (classId: string) => {
        try {
            setLoading(true);
            const response = await deletePayment(classId);
            if (response?.message === 'ok') {
                await loadPayments();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    const handleSearch = (value: string) => {
        setSearchText(value);
    };
    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || 1);
        setPageSize(pagination.pageSize || 20);
        setLoading(true);
    };
    const handleClassTypeChange = (checkedValues: any) => {
        setClassTypes(checkedValues);
        localStorage.setItem('classTypes', JSON.stringify(checkedValues));
    };
    const loadPayments = async () => {
        try {
            setLoading(true);
            const { payments, total } = await getPayments({ offset: (currentPage - 1) * pageSize, limit: pageSize, search: searchText, classTypes });
            setPaymentsData(transformPaymentsToTableData(payments));
            setTotal(total);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadPayments();
    }, [currentPage, pageSize, searchText, classTypes]);

    return (
        <MainLayout activeItem="payments" breadcrumbItems={breadcrumbRoutes}>
            <Row gutter={[16, 16]} className="w-full flex justify-between items-center">
                <Col xs={24} md={8} className="flex justify-start items-center">
                    <Checkbox.Group options={CLASS_TYPE} defaultValue={classTypes} onChange={handleClassTypeChange} />
                </Col>
                <Col xs={24} md={8} className="flex justify-end">
                    <Search placeholder="Buscar clases" onSearch={handleSearch} style={{ marginBottom: 16, marginLeft: 16 }} />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="w-full">
                <Col xs={24}>
                    <div className="w-full">
                        <Spin spinning={loading}>
                            <Table
                                style={{ whiteSpace: 'pre' }}
                                columns={columns}
                                dataSource={paymentsData}
                                pagination={{ current: currentPage, pageSize, total }}
                                rowKey="_id"
                                onChange={handleTableChange}
                            />
                        </Spin>
                    </div>
                </Col>
            </Row>
        </MainLayout>
    );
};

export default PaymentsPage;