import React, { useEffect, useState } from 'react';
import MainLayout from '../../components/mainLayout';
import Search from 'antd/es/input/Search';
import { Button, Col, Modal, Row, Spin, Table, TableColumnsType, TablePaginationConfig, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { AnyObject } from 'antd/es/_util/type';
import { Link } from 'react-router-dom';
import { deleteUser, getUsers, transformUsersToTableData } from '../../services/user';

const UsersPage: React.FC = () => {
    const [usersData, setUsersData] = useState([] as AnyObject[]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = useState(20);
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/users/list', breadcrumbName: 'Usuarios' },
    ];
    const columns: TableColumnsType<AnyObject> = [
        { title: 'Nombre', dataIndex: 'name', key: 'name' },
        { title: 'Apellido', dataIndex: 'lastname', key: 'lastname' },
        { title: 'Usuario', dataIndex: 'username', key: 'username' },
        { title: 'Tipo', dataIndex: 'type', key: 'type' },
        { title: 'Estado', dataIndex: 'status', key: 'status' },
        {
            title: 'Acciones',
            key: 'actions',
            render: (prop) => (
                <span>
                    <Tooltip title="Editar Usuario">
                        <Link to={`/users/edit/${prop.actions.userId}`}>
                            <Button style={{ marginRight: 16 }} icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Eliminar Usuario">
                        <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => {
                            Modal.confirm({
                                title: '¿Estás seguro de que quieres eliminar este usuario?',
                                content: 'Esta acción no se puede deshacer.',
                                okText: 'Sí',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk() {
                                    handleDeleteUser(prop.actions.userId);
                                },
                            });
                        }} />
                    </Tooltip>
                </span>
            ),
            responsive: ['md']
        }
    ];
    const handleSearch = (value: string) => {
        setSearchText(value);
    };
    const handleDeleteUser = async (userId: string) => {
        try {
            setLoading(true);
            const response = await deleteUser(userId);
            if (response?.message === 'ok') {
                await loadUsers();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || 1);
        setPageSize(pagination.pageSize || 20);
        setLoading(true);
    };
    const loadUsers = async () => {
        try {
            setLoading(true);
            const { users, total } = await getUsers({ offset: (currentPage - 1) * pageSize, limit: pageSize, search: searchText });
            setUsersData(transformUsersToTableData(users));
            setTotal(total);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadUsers();
    }, [currentPage, pageSize, searchText]);

    return (
        <MainLayout activeItem="users" breadcrumbItems={breadcrumbRoutes}>
            <Row gutter={[16, 16]} className="w-full flex justify-end items-center">
                <Col xs={24} md={14} lg={10} xl={10} className="flex">
                    <Link to="/users/create">
                        <Button type="primary" icon={<PlusOutlined />}>Crear usuario</Button>
                    </Link>
                    <Search placeholder="Buscar usuario" onSearch={handleSearch} style={{ marginBottom: 16, marginLeft: 16 }} />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="w-full">
                <Col xs={24}>
                    <div className="w-full">
                        <Spin spinning={loading}>
                            <Table
                                style={{ whiteSpace: 'pre' }}
                                columns={columns}
                                dataSource={usersData}
                                pagination={{ current: currentPage, pageSize, total }}
                                rowKey="_id"
                                onChange={handleTableChange}
                            />
                        </Spin>
                    </div>
                </Col>
            </Row>
        </MainLayout>
    );
};

export default UsersPage;