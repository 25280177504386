import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../components/mainLayout';
import { Button, Form, Input, Modal, Select, Row, Col, Divider } from 'antd';
import { IUserEdit, editUser, getUser } from '../../services/user';

interface FormValues {
    name: string;
    lastName: string;
    status: 'Activo' | 'Inactivo';
    type: 'administrador' | 'general';
    username: string;
    password: string;
}

const EditUserPage: React.FC = () => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState("");
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/users/list', breadcrumbName: 'Usuarios' },
        { path: '/users/edit', breadcrumbName: 'Editar usuario' },
    ];

    const onFinish = async (values: FormValues) => {
        const userData: IUserEdit = {
            nombre: values.name,
            apellido: values.lastName,
            estado: values.status,
            tipo: values.type,
            usuario: values.username,
        };
        if (values.password && values.password.trim() !== '') {
            userData.clave = values.password;
        }
        setLoading(true);
        try {
            if (!id) return;
            const result = await editUser(id, userData);
            if (result.message === 'ok') {
                setMessage('Usuario actualizado correctamente');
            } else {
                setMessage('No se pudo actualizar el usuario');
            }
            setIsModalVisible(true);
        } catch (error: any) {
            setIsModalVisible(true);
            setMessage(`Error: ${error.message}`);
        }
        setLoading(false);
    };

    useEffect(() => {
        const init = async () => {
            if (!id) return;
            const user = await getUser(id);
            form.setFieldsValue({
                name: user.nombre,
                lastName: user.apellido,
                status: user.estado,
                type: user.tipo,
                username: user.usuario,
            });
        };
        init();
    }, []);

    return (
        <MainLayout activeItem="users" breadcrumbItems={breadcrumbRoutes}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={14} xl={12}>
                        <Divider>Información del Usuario</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Ingresa el nombre' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="lastName" label="Apellido" rules={[{ required: true, message: 'Ingresa el apellido' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="status" label="Estado">
                                    <Select options={[
                                        { label: 'Activo', value: 'Activo' },
                                        { label: 'Inactivo', value: 'Inactivo' },
                                    ]} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="type" label="Tipo">
                                    <Select options={[
                                        { label: 'Administrador', value: 'administrador' },
                                        { label: 'General', value: 'general' },
                                    ]} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="username" label="Usuario" rules={[{ required: true, message: 'Ingresa el usuario' }]}>
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="password" label="Clave">
                                    <Input.Password placeholder='********' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Actualizar usuario
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                title="Resultado"
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsModalVisible(false)}>
                        OK
                    </Button>,
                ]}
            >
                <p>{message}</p>
            </Modal>
        </MainLayout>
    );
};

export default EditUserPage;