import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import MainLayout from '../../components/mainLayout';
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Switch } from 'antd';
import { PlusOutlined, DeleteOutlined, PrinterOutlined } from '@ant-design/icons';
import { RuleObject } from 'antd/es/form';
import { IPaymentData, editPayment, getPayment } from '../../services/payment';
import { PAYMENT_TYPE } from '../../utils/constants';
import { useAuth } from '../../contexts/authContext';
import ClassSummary, { IClassSummary } from '../classes/components/classSummary';

interface IPayment {
    _id: string;
    code: number;
    paymentType: string;
    paymentValue: number;
    paymentDate: Date;
    operation: string;
}

interface FormValues {
    total: number;
    expiration_alert: boolean;
    enrollment: boolean;
    description: string;
    discountEnabled: boolean;
    discountType: string;
    discountValue: number;
    payments: IPayment[];
    debt: boolean;
}

const EditPaymentPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    const { session } = useAuth();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [discountEnabled, setDiscountEnabled] = useState(false);
    const [message, setMessage] = useState("");
    const [paymentData, setPaymentData] = useState<IPaymentData>();
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/payment/list', breadcrumbName: 'Pagos' },
        { path: '/payment/edit', breadcrumbName: 'Editar pago' },
    ];

    const onFinish = async (values: FormValues) => {
        if (!id) return;
        try {
            setLoading(true);
            const paymentData = {
                total: values.total,
                alerta_vencimiento: values.expiration_alert,
                matricula: values.enrollment,
                descripcion: values.description,
                descuento: values.discountEnabled ? { tipo: values.discountType, valor: values.discountValue } : null,
                pagos: values.payments.map((payment) => ({
                    _id: payment._id,
                    codigo: payment.code ?? Date.now(),
                    tipo: payment.paymentType,
                    valor: payment.paymentValue,
                    fecha: payment.paymentDate,
                    operacion: payment.operation,
                })),
                debe: values.debt,
            };
            const { message } = await editPayment(id, paymentData);
            if (message === 'ok') setMessage('Pago actualizado correctamente.');
        } catch (error) {
            console.error(error);
            setMessage('Ha ocurrido un error. Por favor, intenta de nuevo.');
        } finally {
            setLoading(false);
        }
        setIsModalVisible(true);
    };
    const validatePayments = (_rule: RuleObject, value: IPayment[]) => {
        const total = form.getFieldValue('total');
        const totalPayments = value.reduce((acc, cur) => {
            return acc + cur?.paymentValue ?? 0;
        }, 0);
        if (totalPayments > total) {
            return Promise.reject(new Error('La suma de los pagos supera el total'));
        }
        form.setFieldValue('debt', totalPayments < total);
        return Promise.resolve();
    };
    const printReceipt = () => {
        if (!paymentData) return;
        const otherValues = form.getFieldsValue();
        const classData: IClassSummary = {
            courseName: paymentData?.curso.nombre ?? '',
            studentName: paymentData?.alumno ? `${paymentData.alumno.nombre} ${paymentData.alumno.apellido}` : '',
            studentId: paymentData?.alumno.documento ?? '',
            teacher: paymentData?.clases[0]?.profesor ? `${paymentData.clases[0].profesor.nombre} ${paymentData.clases[0].profesor.apellido}` : '',
            dates: paymentData?.clases.map((clase) => new Date(clase.fecha).toLocaleDateString() + ' ' + new Date(clase.fecha).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: false })) ?? [],
            amount: otherValues.total,
            discount: otherValues.discountValue,
            cashier: session?.username ?? '',
            type: paymentData?.tipo_clase ?? '',
        };
        const printWindow = window.open('', '_blank');
        if (!printWindow) return;
        const rootElement = printWindow.document.createElement('div');
        printWindow.document.body.appendChild(rootElement);
        ReactDOM.render(<ClassSummary classData={classData} session={session} />, rootElement);
        printWindow.print();
    };

    useEffect(() => {
        const init = async () => {
            if (!id) return;
            const paymentInfo = await getPayment(id);
            setPaymentData(paymentInfo);
            form.setFieldsValue({
                total: paymentInfo.total,
                expiration_alert: paymentInfo.alerta_vencimiento,
                enrollment: paymentInfo.matricula,
                description: paymentInfo.descripcion,
                discountEnabled: !!paymentInfo.descuento?.valor,
                discountType: paymentInfo.descuento?.tipo,
                discountValue: paymentInfo.descuento?.valor,
                payments: paymentInfo.pagos.map((payment) => ({
                    _id: payment._id,
                    code: payment.codigo,
                    paymentType: payment.tipo,
                    paymentValue: payment.valor,
                    paymentDate: dayjs(payment.fecha),
                    operation: payment.operacion,
                })),
                debt: paymentInfo.debe,
            });
        };
        init();
    }, []);

    return (
        <MainLayout activeItem="payments" breadcrumbItems={breadcrumbRoutes}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={6} lg={4} xl={3}>
                        <Form.Item name="debt" label="Debe" valuePropName="checked" hidden>
                            <Switch checkedChildren="Si" unCheckedChildren="No" />
                        </Form.Item>
                        <Form.Item name="total" label="Total pago" rules={[{ required: true }]}>
                            <InputNumber prefix='S/' min={1} step={0.1} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={4} xl={3}>
                        <Form.Item name="enrollment" label="Matrícula" valuePropName="checked">
                            <Switch checkedChildren="Si" unCheckedChildren="No" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                        <Form.Item name="expiration_alert" label="Alerta de vencimiento" valuePropName="checked">
                            <Switch checkedChildren="Si" unCheckedChildren="No" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={6} lg={2} xl={2}>
                        <Form.Item name="discountEnabled" label="Descuento" valuePropName="checked">
                            <Switch
                                checkedChildren="Si"
                                unCheckedChildren="No"
                                onChange={(checked) => setDiscountEnabled(checked)}
                            />
                        </Form.Item>
                    </Col>
                    {discountEnabled && (
                        <>
                            <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                                <Form.Item name="discountType" label="Tipo de Descuento" rules={[{ required: true }]}>
                                    <Select>
                                        <Select.Option value="porcentaje">Porcentaje</Select.Option>
                                        <Select.Option value="valor">Valor</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                                <Form.Item name="discountValue" label="Valor de Descuento" rules={[{ required: true }]}>
                                    <InputNumber min={0} step={0.1} />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <Form.List name="payments" rules={[{ validator: validatePayments }]}>
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map(field => (
                                        <Card key={field.key} style={{ marginBottom: '15px' }}>
                                            <Row gutter={16}>
                                                <Col span={14}>
                                                    <Form.Item
                                                        {...{ ...field, key: undefined }}
                                                        name={[field.name, 'paymentType']}
                                                        rules={[{ required: true, message: 'Ingresa medio de pago' }]}
                                                    >
                                                        <Select placeholder="Medio de pago">
                                                            {Object.keys(PAYMENT_TYPE).map((key) => (
                                                                <Select.Option key={key} value={key}>
                                                                    {PAYMENT_TYPE[key]}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item
                                                        {...{ ...field, key: undefined }}
                                                        name={[field.name, 'paymentValue']}
                                                        rules={[{ required: true, message: 'Ingresa el valor' }]}
                                                    >
                                                        <InputNumber prefix='S/' placeholder="Valor" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => remove(field.name)} />
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <Form.Item
                                                        {...{ ...field, key: undefined }}
                                                        name={[field.name, 'paymentDate']}
                                                        rules={[{ required: true, message: 'Ingresa la fecha' }]}
                                                    >
                                                        <DatePicker placeholder="Fecha" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...{ ...field, key: undefined }}
                                                        name={[field.name, 'code']}
                                                        hidden
                                                    >
                                                        <Input placeholder="Código" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...{ ...field, key: undefined }}
                                                        name={[field.name, 'operation']}
                                                    >
                                                        <Input placeholder="Código de operación" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Agregar Pago
                                        </Button>
                                        {errors.length > 0 && (<Form.ErrorList errors={errors} />)}
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <Form.Item name="description" label="Descripción">
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12} sm={12} md={6} lg={5} xl={3}>
                        <Button loading={loading} type="primary" htmlType="submit">
                            Actualizar Pago
                        </Button>
                    </Col>
                    <Col xs={12} sm={12} md={5} lg={5} xl={3}>
                        <Button icon={<PrinterOutlined />} onClick={printReceipt}>
                            Imprimir Recibo
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Modal
                title="Resultado"
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsModalVisible(false)}>
                        OK
                    </Button>,
                ]}
            >
                <p>{message}</p>
            </Modal>
        </MainLayout>
    );
};

export default EditPaymentPage;