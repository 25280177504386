import { BASE_URL } from '../utils/constants';

export const apiCall = async <T>({ url, method = 'GET', data = null, responseType = 'json' }: { url: string; method: string; data: any, responseType?: string }): Promise<T> => {
    const token = localStorage.getItem('token');
    const headers: HeadersInit = {
        'Content-Type': 'application/json'
    };
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    const options = {
        method,
        headers,
        body: data ? JSON.stringify(data) : null
    };

    const response = await fetch(`${BASE_URL}${url}`, options);

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    switch (responseType) {
        case 'json':
            return await response.json();
        case 'blob':
            return await response.blob() as Blob as T;
        default:
            throw new Error(`Unsupported response type: ${responseType}`);
    }
};