import React, { useEffect, useState } from 'react';
import AnalysisCard from './components/analysisCard';
import { Col, Row } from 'antd';
import MainLayout from '../../components/mainLayout';
import icUsers from '../../assets/images/icons/ic_glass_users.png';
import icBag from '../../assets/images/icons/ic_glass_bag.png';
import icMessage from '../../assets/images/icons/ic_glass_message.png';
import Color from 'color';
import { getActiveStudentsReport, getTotalDebtReport, getTotalIncomeReport } from '../../services/report';
import moment from 'moment';
import 'moment/locale/es';
import { useAuth } from '../../contexts/authContext';

const HomePage: React.FC = () => {
    const { isAdmin, token } = useAuth();
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
    ];
    const [activeStudents, setActiveStudents] = useState(0);
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalDebt, setTotalDebt] = useState(0);

    useEffect(() => {
        if (!token) return;
        getActiveStudentsReport().then((result) => {
            setActiveStudents(result.total);
        });
        getTotalDebtReport().then((result) => {
            setTotalDebt(result.total);
        });
        
        if (!isAdmin) return;
        getTotalIncomeReport().then((result) => {
            setTotalIncome(result.total);
        });
    }, [isAdmin, token]);

    return (
        <MainLayout activeItem="dashboard" breadcrumbItems={breadcrumbRoutes}>
            <Row gutter={[16, 16]}>
                <Col md={12} lg={12} xl={6} span={24}>
                    <AnalysisCard
                        cover={icUsers}
                        title={activeStudents.toString()}
                        subtitle="Alumnos con clases activas"
                        style={{
                            color: 'rgb(0, 146, 179)',
                            background: `linear-gradient(135deg, ${Color('rgb(0, 146, 179)')
                                .alpha(0.2)
                                .toString()}, ${Color('rgba(0, 184, 217, 0.2)').alpha(0.2).toString()}) rgb(255, 255, 255)`,
                        }}
                    />
                </Col>
                <Col md={12} lg={12} xl={6} span={24}>
                    <AnalysisCard
                        cover={icMessage}
                        title={`S/ ${totalDebt.toString()}`}
                        subtitle="Deudas de alumnos"
                        style={{
                            color: 'rgb(217, 88, 52)',
                            background: `linear-gradient(135deg, ${Color('rgb(217, 88, 52)')
                                .alpha(0.2)
                                .toString()}, ${Color('rgba(217, 88, 52, 0.2)').alpha(0.2).toString()}) rgb(255, 255, 255)`,
                        }}
                    />
                </Col>
                {isAdmin && (
                    <Col md={12} lg={12} xl={6} span={24}>
                        <AnalysisCard
                            cover={icBag}
                            title={`S/ ${totalIncome.toString()}`}
                            subtitle={`Ingresos de ${moment().locale('es').format('MMMM')}`}
                            style={{
                                color: 'rgb(0, 128, 89)',
                                background: `linear-gradient(135deg, ${Color('rgb(0, 128, 89)')
                                    .alpha(0.2)
                                    .toString()}, ${Color('rgba(0, 167, 111, 0.2)').alpha(0.2).toString()}) rgb(255, 255, 255)`,
                            }}
                        />
                    </Col>
                )}
            </Row>
        </MainLayout>
    );
};

export default HomePage;