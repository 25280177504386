import React, { useEffect, useState } from 'react';
import MainLayout from '../../components/mainLayout';
import Search from 'antd/es/input/Search';
import { Button, Col, Row, Spin, Table, TableColumnsType, TablePaginationConfig, Tooltip, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { AnyObject } from 'antd/es/_util/type';
import { Link } from 'react-router-dom';
import { deleteCourse, getCoursesTable, transformCoursesToTableData } from '../../services/course';

const CoursesPage: React.FC = () => {
    const [coursesData, setCoursesData] = useState([] as AnyObject[]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [searchText, setSearchText] = React.useState('');
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/courses/list', breadcrumbName: 'Cursos' },
    ];
    const columns: TableColumnsType<AnyObject> = [
        { title: 'Nombre', dataIndex: 'name', key: 'name' },
        { title: 'Estado', dataIndex: 'state', key: 'state' },
        {
            title: 'Acciones',
            key: 'actions',
            render: (prop) => (
                <span>
                    <Tooltip title="Editar Curso">
                        <Link to={`/courses/edit/${prop.actions.courseId}`}>
                            <Button style={{ marginRight: 16 }} icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Eliminar Curso">
                        <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => {
                            Modal.confirm({
                                title: '¿Estás seguro de que quieres eliminar este curso?',
                                content: 'Esta acción no se puede deshacer.',
                                okText: 'Sí',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk() {
                                    handleDeleteCourse(prop.actions.courseId);
                                },
                            });
                        }} />
                    </Tooltip>
                </span>
            ),
            responsive: ['md']
        }
    ];
    const handleDeleteCourse = async (courseId: string) => {
        try {
            setLoading(true);
            const response = await deleteCourse(courseId);
            if (response?.message === 'ok') {
                await loadCourses();
            }
            if (response?.error && (response.error === 'clases asociadas')) {
                Modal.error({
                    title: 'No se puede eliminar este curso',
                    content: 'Hay clases asociadas a este curso. Elimina las clases primero.',
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    const handleSearch = (value: string) => {
        setSearchText(value);
    };
    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || 1);
        setPageSize(pagination.pageSize || 20);
        setLoading(true);
    };
    const loadCourses = async () => {
        try {
            setLoading(true);
            const { courses, total } = await getCoursesTable({ offset: (currentPage - 1) * pageSize, limit: pageSize, search: searchText });
            setCoursesData(transformCoursesToTableData(courses));
            setTotal(total);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadCourses();
    }, [currentPage, pageSize, searchText]);

    return (
        <MainLayout activeItem="courses" breadcrumbItems={breadcrumbRoutes}>
            <Row gutter={[16, 16]} className="w-full flex justify-end items-center">
                <Col xs={24} md={14} lg={10} xl={10} className="flex">
                    <Link to="/courses/create">
                        <Button type="primary" icon={<PlusOutlined />}>Crear curso</Button>
                    </Link>
                    <Search placeholder="Buscar curso" onSearch={handleSearch} style={{ marginBottom: 16, marginLeft: 16 }} />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="w-full">
                <Col xs={24}>
                    <div className="w-full">
                        <Spin spinning={loading}>
                            <Table
                                style={{ whiteSpace: 'pre' }}
                                columns={columns}
                                dataSource={coursesData}
                                pagination={{ current: currentPage, pageSize, total }}
                                rowKey="_id"
                                onChange={handleTableChange}
                            />
                        </Spin>
                    </div>
                </Col>
            </Row>
        </MainLayout>
    );
};

export default CoursesPage;