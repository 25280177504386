import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../components/mainLayout';
import { Button, Form, Input, Modal, Select, DatePicker, Row, Col, Divider } from 'antd';
import { ITeacherCreate, createTeacher } from '../../services/teacher';
import dayjs from 'dayjs';

interface FormValues {
    document: string;
    status: 'Activo' | 'Inactivo';
    name: string;
    lastName: string;
    birthDate: dayjs.Dayjs;
    gender: 'Hombre' | 'Mujer';
    mobilePhone: string;
    landline: string;
    email: string;
    specialty: string;
    currency: 'PEN' | 'USD' | 'COP';
    baseSalary: number;
    address: string;
}

const CreateTeacherPage: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState("");
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/users/teacher/list', breadcrumbName: 'Profesores' },
        { path: '/users/teacher/create', breadcrumbName: 'Crear profesor' },
    ];

    const onFinish = async (values: FormValues) => {
        const teacherData: ITeacherCreate = {
            documento: values.document,
            estado: values.status,
            nombre: values.name,
            apellido: values.lastName,
            fecha_nacimiento: values.birthDate.toDate(),
            sexo: values.gender,
            tel_movil: values.mobilePhone,
            tel_fijo: values.landline,
            correo: values.email,
            especialidad: values.specialty,
            moneda: values.currency,
            sueldo_base: values.baseSalary,
            ubicacion: {
                direccion: values.address,
            },
        };
        setLoading(true);
        try {
            const result = await createTeacher(teacherData);
            if (result.message === 'ok') {
                resetForm();
                setMessage('Profesor creado correctamente');
            } else {
                setMessage('No se pudo crear el profesor');
            }
            setIsModalVisible(true);
        } catch (error: any) {
            setIsModalVisible(true);
            setMessage(`Error: ${error.message}`);
        }
        setLoading(false);
    };
    const resetForm = () => {
        form.resetFields();
        form.setFieldsValue({
            status: 'Activo',
            currency: 'PEN',
        });
    };

    useEffect(() => {
        resetForm();
    }, []);

    return (
        <MainLayout activeItem="teachers" breadcrumbItems={breadcrumbRoutes}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={14} xl={12}>
                        <Divider>Información del Profesor</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="document" label="Documento">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="status" label="Estado">
                                    <Select options={[
                                        { label: 'Activo', value: 'Activo' },
                                        { label: 'Inactivo', value: 'Inactivo' },
                                    ]} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Ingresa el nombre' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="lastName" label="Apellido" rules={[{ required: true, message: 'Ingresa el apellido' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item name="birthDate" label="Fecha de Nacimiento" rules={[{ required: true, message: 'Ingresa la fecha' }]}>
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Form.Item name="gender" label="Sexo" rules={[{ required: true, message: 'Ingresa el sexo' }]}>
                                    <Select options={[
                                        { label: 'Hombre', value: 'Hombre' },
                                        { label: 'Mujer', value: 'Mujer' },
                                    ]} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item name="mobilePhone" label="Teléfono Celular" rules={[{ required: true, message: 'Ingresa el teléfono' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Form.Item name="landline" label="Teléfono Fijo">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="email" label="Correo" rules={[{ message: 'Ingresa el correo', type: 'email' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="specialty" label="Especialidad" rules={[{ required: true, message: 'Ingresa la especialidad' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="currency" label="Moneda" rules={[{ required: true, message: 'Ingresa la moneda' }]}>
                                    <Select options={[
                                        { label: 'PEN', value: 'PEN' },
                                        { label: 'USD', value: 'USD' },
                                        { label: 'COP', value: 'COP' },
                                    ]} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="baseSalary" label="Sueldo Base" rules={[{ required: true, message: 'Ingresa el sueldo base' }]}>
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name="address" label="Dirección">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Crear profesor
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                title="Resultado"
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsModalVisible(false)}>
                        Crear otro profesor
                    </Button>,
                    <Button key="back" onClick={() => navigate('/users/teacher/list', { replace: true })}>
                        Volver al listado de profesores
                    </Button>
                ]}
            >
                <p>{message}</p>
            </Modal>
        </MainLayout>
    );
};

export default CreateTeacherPage;