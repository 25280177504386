import React from 'react';
import { Form, Input, Button, Row, Col, message, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useAuth } from '../../contexts/authContext';
import { login } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import { images } from './images';

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { setToken } = useAuth();
  const randomImageName = Object.keys(images)[Math.floor(Math.random() * Object.keys(images).length)];
  const randomImageSrc = images[randomImageName];
  const onFinish = async (values: any) => {
    try {
      const data = await login(values);
      setToken(data.token);
      navigate('/');
    } catch (error) {
      console.error(error);
      message.error('Error al iniciar sesión. Por favor, intenta de nuevo.');
    }
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh', backgroundImage: `url(${randomImageSrc})`, backgroundSize: 'cover' }}>
      <Col xs={18} sm={14} md={12} lg={10} xl={6}>
        <Card title="Iniciar sesión">
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Por favor ingresa tu nombre de usuario!' }]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Usuario" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Por favor ingresa tu contraseña!' }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Contraseña"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Iniciar sesión
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginPage;