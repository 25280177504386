import React from 'react';
import { ISession } from '../../../contexts/authContext';

export interface IClassSummary {
    courseName: string;
    studentName: string;
    studentId: string;
    teacher: string;
    dates: string[];
    amount: number;
    discount: number;
    cashier: string;
    type: string;
}

const ClassSummary = ({ classData, session }: { classData: IClassSummary, session: ISession|null }) => {

    return (
        <div style={{ fontFamily: 'monospace', width: '80mm', wordWrap: 'break-word' }}>
            <p>Haz Crecer Tu talento</p>
            <p>{session?.institute?.nombre}</p>
            <p>{session?.institute?.ubicacion?.direccion}</p>
            <p>{session?.institute?.tel_fijo} / {session?.institute?.tel_movil}</p>
            <p>{session?.institute?.pagina_web}</p>
            <p>{new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date().toLocaleTimeString(undefined, { hour12: false, hour: '2-digit', minute: '2-digit' })}</p>
            <p>-------------------------------</p>
            <p>{classData.studentName}</p>
            <p>DNI/C.E: {classData.studentId}</p>
            <p>-------------------------------</p>
            <p>TIPO: {classData?.type}</p>
            <p>CURSO: {classData.courseName}</p>
            <p>PROFESOR: {classData.teacher}</p>
            <p>FECHAS Y HORAS:</p>
            {classData.dates.map((date, index) => (
                <p key={index}>{date}</p>
            ))}
            <p>-------------------------------</p>
            <p>MONTO: S/ {classData.amount}</p>
            <p>DESCUENTO: S/ {classData.discount}</p>
            <p>CAJA: {session?.username}</p>
        </div>
    );
};

export default ClassSummary;