import { AnyObject } from 'antd/es/_util/type';
import { apiCall } from './api';
import { IPaymentSchema } from './payment';
import { ITeacher } from './teacher';
import { IStudent } from './student';
import { ICourse } from './course';
import { formatDate } from '../utils';

interface IResponseClasses {
    classes: IClass[];
    total: number;
}
interface IResponse {
    message: string;
}
type IResponseDeleteClass = IResponse;
type IResponseEditClass = IResponse;

export interface IClass {
    _id: string;
    alumno: IStudent[];
    profesor: ITeacher[];
    curso: ICourse[];
    pago: IPaymentSchema[];
    institucion: string;
    vista: boolean;
    tipo: string;
    descripcion: string;
    duracion: number;
    pagar_profesor: boolean;
    valor_profesor: number;
    fecha: Date;
    notificar: boolean;
}

export type IClassEdit = Omit<IClass, 'alumno' | 'profesor' | 'curso' | 'vista' | 'institucion' | 'pago'> & {
    alumno: string;
    profesor: string;
    curso: string;
    vista: string;
}

export type IClassCreate = Omit<IClass, '_id' | 'alumno' | 'profesor' | 'curso' | 'institucion' | 'pago'> & {
    alumno: string;
    profesor: string;
    curso: string;
};

export type IClassPayment = Omit<IClass, 'alumno' | 'profesor' | 'curso' | 'pago'> & {
    alumno: string;
    curso: string;
    profesor: ITeacher;
};

export type IClassCreateResponse = IClassCreate & {
    _id: string;
};

export const getClass = async (classId: string) => {
    return await apiCall<IClass>({ url: `/class/${classId}`, method: 'GET', data: null });
};

export const getClasses = async ({ search = '', limit = 20, offset = 0, classTypes = ['Escuela', 'Domicilio'] } = {}) => {
    return await apiCall<IResponseClasses>({ url: '/class/list', method: 'POST', data: { search, limit, offset, classTypes } });
};

export const createClass = async (classData: IClassCreate) => {
    return await apiCall<IClassCreateResponse>({ url: '/class', method: 'POST', data: classData });
};

export const editClass = async (classId: string, classData: IClassEdit) => {
    return await apiCall<IResponseEditClass>({ url: `/class/${classId}`, method: 'PUT', data: classData });
};

export const deleteClass = async (classId: string) => {
    return await apiCall<IResponseDeleteClass>({ url: `/class/${classId}`, method: 'DELETE', data: null });
};

export const transformClassesToTableData = (classes: IClass[]): AnyObject[] => {
    return classes.map((classItem) => {
        return {
            _id: classItem._id,
            student: classItem.alumno.map(a => `${a.nombre} ${a.apellido}`).join(', '),
            course: classItem.curso.map(c => c.nombre).join(', '),
            teacher: classItem.profesor.map(p => `${p.nombre} ${p.apellido}`).join(', '),
            type: classItem.tipo,
            duration: classItem.duracion,
            view: classItem.vista,
            date: formatDate(classItem.fecha),
            actions: {
                classId: classItem._id,
                paymentId: classItem.pago[0]?._id
            }
        };
    });
};