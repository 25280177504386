import { apiCall } from './api';

interface IUniqueStudentsPerDate {
    _id: string;
    fecha: Date;
    count: number;
    alumnos: {
        nombre: string;
        edad: number;
    }[];
}

interface IResponse {
    message: string;
    error?: string;
}

export interface ICoursesTable {
    courses: ICourse[];
    total: number;
}

export interface ICourse {
    _id: string;
    nombre: string;
    horarios: {
        dias: string[];
        hora: string;
        cupo: number;
    }[];
    estado: string;
    institucion: string;
}

export type ICourseCreate = Omit<ICourse, '_id'|'horarios'|'institucion'>;

export const getCourse = async (courseId: string): Promise<ICourse> => {
    return await apiCall<ICourse>({ url: `/course/${courseId}`, method: 'GET', data: null });
};

export const getCourses = async (): Promise<ICourse[]> => {
    return await apiCall<ICourse[]>({ url: '/course/list', method: 'GET', data: null });
};

export const getCoursesTable = async ({ limit = 20, offset = 0, search = '' } = {}): Promise<ICoursesTable> => {
    return await apiCall<ICoursesTable>({ url: '/course/table', method: 'POST', data: { limit, offset, search } });
};

export const createCourse = async (course: ICourseCreate): Promise<IResponse> => {
    return await apiCall<IResponse>({ url: '/course', method: 'POST', data: course });
};

export const updateCourse = async (courseId: string, course: ICourseCreate): Promise<IResponse> => {
    return await apiCall<IResponse>({ url: `/course/${courseId}`, method: 'PUT', data: course });
};

export const deleteCourse = async (courseId: string): Promise<IResponse> => {
    return await apiCall<IResponse>({ url: `/course/${courseId}`, method: 'DELETE', data: null });
};

export const getUniqueStudentsPerDate = async (courseId: string, classDates: Date[], classType: string, teacher: string): Promise<IUniqueStudentsPerDate[]> => {
    return await apiCall<IUniqueStudentsPerDate[]>({ url: `/course/${courseId}/occupied-places`, method: 'POST', data: { classDates, classType, teacher } });
};

export const transformCoursesToTableData = (courses: ICourse[]): any[] => {
    return courses.map(course => ({
        _id: course._id,
        name: course.nombre,
        state: course.estado,
        actions: {
            courseId: course._id
        }
    }));
};