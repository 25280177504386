import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../components/mainLayout';
import { Button, Form, Input, Select, Row, Col, Divider, Modal } from 'antd';
import { updateCourse, getCourse, ICourseCreate } from '../../services/course';

interface FormValues {
    name: string;
    status: 'Activo' | 'Inactivo';
}

const EditCoursePage: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState("");
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/courses/list', breadcrumbName: 'Cursos' },
        { path: `/courses/edit/${id}`, breadcrumbName: 'Editar curso' },
    ];

    const onFinish = async (values: FormValues) => {
        if (!id) return;
        setLoading(true);
        try {
            const courseData: ICourseCreate = {
                nombre: values.name,
                estado: values.status,
            };
            const result = await updateCourse(id, courseData);
            if (result.message === 'ok') {
                setMessage('Curso actualizado correctamente');
            } else {
                setMessage('No se pudo actualizar el curso');
            }
            setIsModalVisible(true);
        } catch (error: any) {
            setIsModalVisible(true);
            setMessage(`Error: ${error.message}`);
        }
        setLoading(false);
    };

    const loadCourse = async () => {
        if (!id) return;
        setLoading(true);
        try {
            const course = await getCourse(id);
            form.setFieldsValue({
                name: course.nombre,
                status: course.estado,
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadCourse();
    }, []);

    return (
        <MainLayout activeItem="courses" breadcrumbItems={breadcrumbRoutes}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={14} xl={12}>
                        <Divider>Información del Curso</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Ingresa el nombre' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="status" label="Estado" rules={[{ required: true, message: 'Ingresa el estado' }]}>
                                    <Select options={[
                                        { label: 'Activo', value: 'Activo' },
                                        { label: 'Inactivo', value: 'Inactivo' },
                                    ]} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Actualizar curso
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                title="Resultado"
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsModalVisible(false)}>
                        OK
                    </Button>,
                ]}
            >
                <p>{message}</p>
            </Modal>
        </MainLayout>
    );
};

export default EditCoursePage;