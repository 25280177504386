import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/authContext';

const PrivateRoute = () => {
    const { token } = useAuth();
    return (
        token ? <Outlet /> : <Navigate to="/login" />
    )
}

export default PrivateRoute