import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import MainLayout from '../../components/mainLayout';
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Switch } from 'antd';
import { IStudent, getStudents } from '../../services/student';
import { ITeacher, getTeachers } from '../../services/teacher';
import { ICourse, getCourses } from '../../services/course';
import { IClassEdit, editClass, getClass } from '../../services/class';

interface FormValues {
    student: string;
    course: string;
    teacher: string;
    status: string;
    date: Date;
    duration: number;
    payTeacher: boolean;
    paymentPerClass: number;
    notifyByEmail: boolean;
    description: string;
    type: string;
}

const EditClassPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState("");
    const [students, setStudents] = useState<IStudent[]>([]);
    const [courses, setCourses] = useState<ICourse[]>([]);
    const [teachers, setTeachers] = useState<ITeacher[]>([]);
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/class/list', breadcrumbName: 'Clases' },
        { path: '/class/edit', breadcrumbName: 'Editar clase' },
    ];

    const onFinish = async (values: FormValues) => {
        if (!id) return;
        const classData: IClassEdit = {
            _id: id,
            alumno: values.student,
            curso: values.course,
            profesor: values.teacher,
            vista: values.status,
            fecha: values.date,
            duracion: values.duration,
            pagar_profesor: values.payTeacher,
            valor_profesor: values.paymentPerClass,
            notificar: values.notifyByEmail,
            descripcion: values.description,
            tipo: values.type,
        };
        try {
            setLoading(true);
            const { message } = await editClass(id, classData);
            if (message === 'ok') setMessage('Clase actualizada correctamente.');
        } catch (error) {
            console.error(error);
            setMessage('Ha ocurrido un error. Por favor, intenta de nuevo.');
        } finally {
            setLoading(false);
        }
        setIsModalVisible(true);
    };
    const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        const init = async () => {
            const [students, teachers, courses] = await Promise.all([getStudents(), getTeachers(), getCourses()]);
            setStudents(students);
            setTeachers(teachers);
            setCourses(courses);
            if (!id) return;
            const classInfo = await getClass(id);
            form.setFieldsValue({
                student: classInfo.alumno,
                course: classInfo.curso,
                teacher: classInfo.profesor,
                status: classInfo.vista,
                date: dayjs(classInfo.fecha),
                duration: classInfo.duracion,
                payTeacher: classInfo.pagar_profesor,
                paymentPerClass: classInfo.valor_profesor,
                notifyByEmail: classInfo.notificar,
                description: classInfo.descripcion,
                type: classInfo.tipo,
            });
        };
        init();
    }, []);

    return (
        <MainLayout activeItem="class" breadcrumbItems={breadcrumbRoutes}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item name="student" label="Alumno" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                filterOption={filterOption}
                                options={students.map((student) => ({
                                    value: student._id,
                                    label: `${student.nombre} ${student.apellido}`,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item name="course" label="Curso" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                filterOption={filterOption}
                                options={courses.map((course) => ({
                                    value: course._id,
                                    label: `${course.nombre}`,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item name="teacher" label="Profesor" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                filterOption={filterOption}
                                options={teachers.map((teacher) => ({
                                    value: teacher._id,
                                    label: `${teacher.nombre} ${teacher.apellido}`,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item name="type" label="Tipo" rules={[{ required: true }]}>
                            <Select>
                                <Select.Option value="Escuela">Escuela</Select.Option>
                                <Select.Option value="Domicilio">Domicilio</Select.Option>
                                <Select.Option value="Virtual">Virtual</Select.Option>
                                <Select.Option value="Demostrativa">Demostrativa</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="date" label="Fecha" rules={[{ required: true }]}>
                            <DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
                        </Form.Item>
                        <Form.Item name="duration" label="Duración (horas)" rules={[{ required: true }]}>
                            <InputNumber min={0.1} step={0.1} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item name="status" label="Estado">
                            <Switch checkedChildren="Vista" unCheckedChildren="No vista" />
                        </Form.Item>
                        <Form.Item name="payTeacher" label="Pagar al profesor?" valuePropName="checked">
                            <Switch checkedChildren="Activo" unCheckedChildren="Inactivo" />
                        </Form.Item>
                        <Form.Item name="paymentPerClass" label="Pago por clase al profesor (Soles)" rules={[{ required: true }]}>
                            <InputNumber prefix='S/' min={0} step={0.1} />
                        </Form.Item>
                        <Form.Item name="notifyByEmail" label="Notificar por correo" valuePropName="checked">
                            <Switch checkedChildren="Activo" unCheckedChildren="Inactivo" />
                        </Form.Item>
                        <Form.Item name="description" label="Descripción">
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item>
                            <Button loading={loading} type="primary" htmlType="submit">
                                Actualizar Clase
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Modal
                title="Resultado"
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsModalVisible(false)}>
                        OK
                    </Button>,
                ]}
            >
                <p>{message}</p>
            </Modal>
        </MainLayout>
    );
};

export default EditClassPage;