import { apiCall } from './api';

export interface ITeacher {
    _id: string;
    nombre: string;
    apellido: string;
    documento: string;
    correo: string;
    tel_movil: string;
    tel_fijo: string;
    fecha_registro: Date;
    fecha_nacimiento: Date;
    ubicacion: {
        direccion: string;
        loc?: { lng: number, lat: number }
    };
    sexo: string;
    especialidad: string;
    sueldo_base: number;
    moneda: string;
    avatar: string;
    estado: string;
    institucion: string;
    usuario: string;
    clave: string;
    token_fcm: string;
}

interface IResponse {
    message: string;
    error?: string;
}

export type ITeacherCreate = Omit<ITeacher, '_id' | 'fecha_registro' | 'institucion' | 'usuario' | 'clave' | 'token_fcm' | 'avatar'>;

export interface ITeacherTable {
    teachers: ITeacher[];
    total: number;
}

export const getTeachers = async (): Promise<ITeacher[]> => {
    return await apiCall<ITeacher[]>({ url: '/teacher/list', method: 'GET', data: null });
};

export const getTeacher = async (teacherId: string): Promise<ITeacher> => {
    return await apiCall<ITeacher>({ url: `/teacher/${teacherId}`, method: 'GET', data: null });
};

export const getTeachersTable = async ({ limit = 20, offset = 0, search = '' } = {}): Promise<ITeacherTable> => {
    return await apiCall<ITeacherTable>({ url: '/teacher/table', method: 'POST', data: { limit, offset, search } });
};

export const createTeacher = async (teacher: ITeacherCreate): Promise<IResponse> => {
    return await apiCall<IResponse>({ url: '/teacher', method: 'POST', data: teacher });
};

export const updateTeacher = async (teacherId: string, teacher: ITeacherCreate): Promise<IResponse> => {
    return await apiCall<IResponse>({ url: `/teacher/${teacherId}`, method: 'PUT', data: teacher });
};

export const deleteTeacher = async (teacherId: string): Promise<IResponse> => {
    return await apiCall<IResponse>({ url: `/teacher/${teacherId}`, method: 'DELETE', data: null });
};

export const transformTeachersToTableData = (teachers: ITeacher[]): any[] => {
    return teachers.map(teacher => ({
        _id: teacher._id,
        name: teacher.nombre,
        lastname: teacher.apellido,
        address: teacher.documento,
        cellphone: teacher.tel_movil,
        phone: teacher.tel_fijo,
        email: teacher.correo,
        speciality: teacher.especialidad,
        actions: {
            teacherId: teacher._id
        }
    }));
};