import { apiCall } from './api';

interface IAttendant {
    _id?: string;
    documento: string;
    nombre: string;
    apellido: string;
    correo: string;
    telefono: string;
    relacion: string;
}

interface ILocation {
    direccion: string;
    loc?: {
        lng: number;
        lat: number;
    };
}

interface IResponse {
    message: string;
    error?: string;
}

export interface IStudent {
    _id: string;
    nombre: string;
    apellido: string;
    documento: string;
    correo: string;
    tel_movil: string;
    tel_fijo: string;
    estado: string;
    fecha_registro?: Date;
    fecha_nacimiento: Date;
    ubicacion: ILocation;
    tipo: string;
    sexo: string;
    acudientes: IAttendant[];
    avatar: string;
    institucion: string;
}

export type IStudentCreate = Omit<IStudent, '_id' | 'fecha_registro' | 'institucion'>;

export interface IStudentTable {
    students: IStudent[];
    total: number;
}

export const getStudent = async (studentId: string): Promise<IStudent> => {
    return await apiCall<IStudent>({ url: `/student/${studentId}`, method: 'GET', data: null });
};

export const getStudents = async (): Promise<IStudent[]> => {
    return await apiCall<IStudent[]>({ url: '/student/list', method: 'GET', data: null });
};

export const getStudentsTable = async ({ limit = 20, offset = 0, search = '' } = {}): Promise<IStudentTable> => {
    return await apiCall<IStudentTable>({ url: '/student/table', method: 'POST', data: { limit, offset, search } });
};

export const createStudent = async (studentData: IStudentCreate): Promise<IResponse> => {
    return await apiCall<IResponse>({ url: '/student', method: 'POST', data: studentData });
};

export const editStudent = async (studentId: string, studentData: IStudentCreate): Promise<IResponse> => {
    return await apiCall<IResponse>({ url: `/student/${studentId}`, method: 'PUT', data: studentData });
};

export const deleteStudent = async (studentId: string): Promise<IResponse> => {
    return await apiCall<IResponse>({ url: `/student/${studentId}`, method: 'DELETE', data: null });
};

export const downloadStudents = async (): Promise<Blob> => {
    return await apiCall<Blob>({ url: '/student/download', method: 'GET', data: null, responseType: 'blob'});
};

export const transformStudentsToTableData = (students: IStudent[]) => {
    return students.map(student => {
        return {
            _id: student._id,
            name: student.nombre,
            lastname: student.apellido,
            address: student.ubicacion?.direccion,
            cellphone: student.tel_movil,
            phone: student.tel_fijo,
            email: student.correo,
            type: student.tipo,
            actions: {
                studentId: student._id
            }
        };
    });
};