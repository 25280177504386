import { apiCall } from './api';

export interface IUser {
    _id: string;
    clave: string;
    institucion: string;
    avatar: string;
    estado: string;
    tipo: string;
    usuario: string;
    apellido: string;
    nombre: string;
}

interface IUserTable {
    users: IUser[];
    total: number;
}

interface IResponse {
    message: string;
    error?: string;
}
type PartiallyOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type IUserEdit = PartiallyOptional<Omit<IUser, '_id' | 'institucion' | 'avatar'>, 'clave'>;
export type IUserCreate = Omit<IUser, '_id' | 'avatar' | 'institucion'>;

export const getUsers = async ({ limit = 20, offset = 0, search = '' } = {}): Promise<IUserTable> => {
    return await apiCall<IUserTable>({ url: '/user/list', method: 'POST', data: { limit, offset, search } });
};

export const getUser = async (userId: string): Promise<IUser> => {
    return await apiCall<IUser>({ url: `/user/${userId}`, method: 'GET', data: null });
};

export const createUser = async (user: IUserCreate): Promise<IResponse> => {
    return await apiCall<IResponse>({ url: '/user', method: 'POST', data: user });
};

export const editUser = async (userId: string, user: IUserEdit): Promise<IResponse> => {
    return await apiCall<IResponse>({ url: `/user/${userId}`, method: 'PUT', data: user });
};

export const deleteUser = async (userId: string): Promise<IResponse> => {
    return await apiCall<IResponse>({ url: `/user/${userId}`, method: 'DELETE', data: null });
};

export const transformUsersToTableData = (users: IUser[]): any[] => {
    return users.map((user) => {
        return {
            _id: user._id,
            name: user.nombre,
            lastname: user.apellido,
            username: user.usuario,
            type: user.tipo,
            status: user.estado,
            actions: {
                userId: user._id
            }
        };
    });
};