export const BASE_URL = 'https://henasoft.crescendoperu.com:8087/api';
export const CLASS_TYPE = ['Escuela', 'Domicilio', 'Virtual', 'Demostrativa'];
export const PAYMENT_TYPE: {[key: string]: string} = {
    'Wallet': 'Billetera digital',
    'Efectivo': 'Efectivo',
    'Credito': 'Crédito',
    'Debito': 'Débito',
    'Deposito_cuenta_empresarial': 'Depósito cuenta empresarial',
    'Deposito_cuenta_gerencia': 'Depósito cuenta gerencia',
    'Pendiente_pago': 'Pendiente de pago',
    'Transferencia_cuenta_empresarial': 'Transferencia cuenta empresarial',
    'Transferencia_cuenta_gerencia': 'Transferencia cuenta gerencia'
};