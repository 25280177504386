import { apiCall } from './api';

interface LoginData {
    username: string;
    password: string;
}

interface LoginResponse {
    token: string;
}

export const login = async (data: LoginData): Promise<LoginResponse> => {
    return await apiCall<LoginResponse>({ url: '/login', method: 'POST', data });
};