import React, { useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { DatePicker, Table, Button, Row, Col, Spin, Statistic, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import MainLayout from '../../components/mainLayout';
import { getIncomeReport } from '../../services/report';
import { formatDate } from '../../utils';
import { PAYMENT_TYPE } from '../../utils/constants';

const { RangePicker } = DatePicker;

const IncomeReportPage: React.FC = () => {
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([dayjs().startOf('month'), dayjs().endOf('month')]);
    const [incomeData, setIncomeData] = useState<any[]>([]);
    const [totalIncome, setTotalIncome] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/reports/income', breadcrumbName: 'Ingresos' },
    ];

    const columns = [
        {
            title: 'Alumno',
            dataIndex: 'student',
            key: 'student',
        },
        {
            title: 'Medio de Pago',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            render: (paymentMethod: string) => PAYMENT_TYPE[paymentMethod],
        },
        {
            title: 'Monto',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => `S/ ${amount}`,
        },
        {
            title: 'Fecha de pago',
            dataIndex: 'date',
            key: 'date',
            render: (date: Date) => formatDate(date),
        },
    ];

    const fetchIncomeData = async () => {
        setLoading(true);
        try {
            if (!dateRange[0] || !dateRange[1]) return;
            const data = await getIncomeReport(dateRange[0], dateRange[1]);
            const total = data.reduce((sum, item) => sum + item.amount, 0);
            setIncomeData(data);
            setTotalIncome(total);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchIncomeData();
    }, []);

    return (
        <MainLayout activeItem="incomeReport" breadcrumbItems={breadcrumbRoutes}>

            <Row gutter={[16, 16]} className="w-full flex justify-end items-center" style={{ marginBottom: 16 }}>
                <Col xs={24} md={10}>
                    <Statistic
                        title="Total de ingresos"
                        value={totalIncome}
                        prefix='S/'
                        valueStyle={{ color: totalIncome > 0 ? '#3f8600' : '#cf1322', fontWeight: 'bold' }}
                    />
                </Col>
                <Col xs={24} md={14} className="flex justify-end">
                    <RangePicker value={dateRange} onChange={(dates) => setDateRange(dates as [Dayjs | null, Dayjs | null])} />
                    <Button type="primary" onClick={fetchIncomeData} style={{ marginLeft: 16, marginRight: 16 }}>Cargar</Button>
                    <CSVLink
                        filename={"income-report.csv"}
                        data={incomeData}
                        className="btn btn-primary"
                    >
                        <Tooltip title="Descargar reporte">
                            <Button icon={<DownloadOutlined />} type="dashed"></Button>
                        </Tooltip>
                    </CSVLink>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="w-full">
                <Col xs={24}>
                    <div className="w-full">
                        <Spin spinning={loading}>
                            <Table
                                columns={columns}
                                dataSource={incomeData}
                                rowKey='_id'
                                pagination={{ pageSize: 30, showSizeChanger: false }}
                            />
                        </Spin>
                    </div>
                </Col>
            </Row>
        </MainLayout>
    );
};

export default IncomeReportPage;