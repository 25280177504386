import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../components/mainLayout';
import { Button, Form, Input, Modal, Select, DatePicker, Card, Row, Col, Divider } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { IStudentCreate, createStudent } from '../../services/student';
import dayjs from 'dayjs';

interface Guardian {
    document: string;
    name: string;
    lastName: string;
    email: string;
    phone: string;
    relationship: string;
}

interface FormValues {
    document: string;
    studentType: 'Escuela' | 'Domicilio' | 'Virtual';
    name: string;
    lastName: string;
    birthDate: dayjs.Dayjs;
    gender: 'Hombre' | 'Mujer';
    mobilePhone: string;
    landline: string;
    email: string;
    status: 'Activo' | 'Inactivo';
    address: string;
    guardians: Guardian[];
}

const CreateStudentPage: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState("");
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/users/student/list', breadcrumbName: 'Alumnos' },
        { path: '/users/student/create', breadcrumbName: 'Crear alumno' },
    ];

    const onFinish = async (values: FormValues) => {
        const studentData: IStudentCreate = {
            avatar: '',
            documento: values.document,
            tipo: values.studentType,
            nombre: values.name,
            apellido: values.lastName,
            fecha_nacimiento: values.birthDate.toDate(),
            estado: values.status,
            tel_movil: values.mobilePhone,
            tel_fijo: values.landline,
            correo: values.email,
            sexo: values.gender,
            ubicacion: {
                direccion: values.address,
            },
            acudientes: values?.guardians?.map(guardian => ({
                documento: guardian.document,
                nombre: guardian.name,
                apellido: guardian.lastName,
                correo: guardian.email,
                telefono: guardian.phone,
                relacion: guardian.relationship,
            })),
        };
        setLoading(true);
        try {
            const result = await createStudent(studentData);
            if (result.message === 'ok') {
                resetForm();
                setMessage('Alumno creado correctamente');
            } else {
                setMessage('No se pudo crear el alumno');
            }
            setIsModalVisible(true);
        } catch (error: any) {
            setIsModalVisible(true);
            setMessage(`Error: ${error.message}`);
        }
        setLoading(false);
    };
    const resetForm = () => {
        form.resetFields();
        form.setFieldsValue({
            status: 'Activo',
        });
    };

    useEffect(() => {
        const init = async () => {

        };
        init();
        resetForm();
    }, []);

    return (
        <MainLayout activeItem="students" breadcrumbItems={breadcrumbRoutes}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={14} xl={12}>
                        <Divider>Información del Alumno</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="studentType" label="Tipo de Alumno" rules={[{ required: true, message: 'Ingresa el tipo de alumno' }]}>
                                    <Select options={[
                                        { label: 'Escuela', value: 'Escuela' },
                                        { label: 'Domicilio', value: 'Domicilio' },
                                        { label: 'Virtual', value: 'Virtual' },
                                    ]} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="document" label="Documento">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Ingresa el nombre' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="lastName" label="Apellido" rules={[{ required: true, message: 'Ingresa el apellido' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item name="birthDate" label="Fecha de Nacimiento" rules={[{ required: true, message: 'Ingresa la fecha' }]}>
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Form.Item name="gender" label="Sexo" rules={[{ required: true, message: 'Ingresa el sexo' }]}>
                                    <Select options={[
                                        { label: 'Hombre', value: 'Hombre' },
                                        { label: 'Mujer', value: 'Mujer' },
                                    ]} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item name="mobilePhone" label="Teléfono Celular" rules={[{ required: true, message: 'Ingresa el teléfono' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Form.Item name="landline" label="Teléfono Fijo">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="email" label="Correo" rules={[{ message: 'Ingresa el correo', type: 'email' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="status" label="Estado">
                                    <Select options={[
                                        { label: 'Activo', value: 'Activo' },
                                        { label: 'Inactivo', value: 'Inactivo' },
                                    ]} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name="address" label="Dirección">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                        <Divider>Acudientes</Divider>
                        <Form.List name="guardians">
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map(field => (
                                        <Card key={field.key} style={{ marginBottom: '15px' }}>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'name']}
                                                        rules={[{ required: true, message: 'Ingresa el nombre' }]}
                                                    >
                                                        <Input placeholder="Nombre" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={10}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'lastName']}
                                                        rules={[{ required: true, message: 'Ingresa el apellido' }]}
                                                    >
                                                        <Input placeholder="Apellido" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}>
                                                    <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => remove(field.name)} />
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'email']}
                                                        rules={[{ required: true, message: 'Ingresa el correo', type: 'email' }]}
                                                    >
                                                        <Input placeholder="Correo" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={10}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'phone']}
                                                        rules={[{ required: true, message: 'Ingresa el teléfono' }]}
                                                    >
                                                        <Input placeholder="Teléfono" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'document']}
                                                        rules={[{ message: 'Ingresa el documento de identidad' }]}
                                                    >
                                                        <Input placeholder="Documento de identidad" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={10}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'relationship']}
                                                        rules={[{ required: true, message: 'Ingresa la relación con el alumno' }]}
                                                    >
                                                        <Input placeholder="Relación" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Agregar acudiente
                                        </Button>
                                        {errors.length > 0 && (<Form.ErrorList errors={errors} />)}
                                    </Form.Item>
                                    <Form.Item>
                                        <Button loading={loading} type="primary" htmlType="submit"> 
                                            Crear alumno
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
            </Form>
            <Modal
                title="Resultado"
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsModalVisible(false)}>
                        Crear otra alumno
                    </Button>,
                    <Button key="back" onClick={() => navigate('/users/student/list', { replace: true })}>
                        Volver al listado de alumnos
                    </Button>
                ]}
            >
                <p>{message}</p>
            </Modal>
        </MainLayout>
    );
};

export default CreateStudentPage;