import React from 'react';
import { ConfigProvider } from 'antd';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import PrivateRoute from './components/privateRoute';
import { AuthProvider } from './contexts/authContext';
import ClassesPage from './pages/classes/list';
import EditClassPage from './pages/classes/edit';
import CreateClassPage from './pages/classes/create';
import EditPaymentPage from './pages/payment/edit';
import PaymentsPage from './pages/payment/list';
import StudentsPage from './pages/student/list';
import CreateStudentPage from './pages/student/create';
import EditStudentPage from './pages/student/edit';
import TeachersPage from './pages/teacher/list';
import CreateTeacherPage from './pages/teacher/create';
import EditTeacherPage from './pages/teacher/edit';
import UsersPage from './pages/user/list';
import EditUserPage from './pages/user/edit';
import CreateUserPage from './pages/user/create';
import CoursesPage from './pages/course/list';
import CreateCoursePage from './pages/course/create';
import EditCoursePage from './pages/course/edit';
import IncomeReportPage from './pages/reports/income';
import locale from 'antd/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
dayjs.locale('es');

function App() {

  return (
    <ConfigProvider locale={locale}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route element={<HomePage />} path="/" />
              <Route element={<ClassesPage />} path="/class/list" />
              <Route element={<EditClassPage />} path="/class/edit/:id" />
              <Route element={<CreateClassPage />} path="/class/create" />
              <Route element={<PaymentsPage />} path="/payment/list" />
              <Route element={<EditPaymentPage />} path="/payment/edit/:id" />
              <Route element={<StudentsPage />} path="/users/student/list" />
              <Route element={<CreateStudentPage />} path="/users/student/create" />
              <Route element={<EditStudentPage />} path="/users/student/edit/:id" />
              <Route element={<TeachersPage />} path="/users/teacher/list" />
              <Route element={<CreateTeacherPage />} path="/users/teacher/create" />
              <Route element={<EditTeacherPage />} path="/users/teacher/edit/:id" />
              <Route element={<UsersPage />} path="/users/list" />
              <Route element={<EditUserPage />} path="/users/edit/:id" />
              <Route element={<CreateUserPage />} path="/users/create" />
              <Route element={<CoursesPage />} path="/courses/list" />
              <Route element={<CreateCoursePage />} path="/courses/create" />
              <Route element={<EditCoursePage />} path="/courses/edit/:id" />
              <Route element={<IncomeReportPage />} path="/reports/income" />
            </Route>
            <Route element={<LoginPage />} path="/login" />
          </Routes>
        </Router>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;