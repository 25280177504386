import React, { useEffect, useState } from 'react';
import MainLayout from '../../components/mainLayout';
import { Button, Form, Input, Modal, Select, Row, Col, Divider } from 'antd';
import { IUserCreate, createUser } from '../../services/user';

interface FormValues {
    name: string;
    lastName: string;
    status: 'Activo' | 'Inactivo';
    type: 'administrador' | 'general';
    username: string;
    password: string;
}

const CreateUserPage: React.FC = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState("");
    const breadcrumbRoutes = [
        { path: '/', breadcrumbName: 'Dashboard' },
        { path: '/users/list', breadcrumbName: 'Usuarios' },
        { path: '/users/create', breadcrumbName: 'Crear usuario' },
    ];

    const onFinish = async (values: FormValues) => {
        const userData: IUserCreate = {
            nombre: values.name,
            apellido: values.lastName,
            estado: values.status,
            tipo: values.type,
            usuario: values.username,
            clave: values.password,
        };
        setLoading(true);
        try {
            const result = await createUser(userData);
            if (result.message === 'ok') {
                resetForm();
                setMessage('Profesor creado correctamente');
            } else if (result.message === 'usuario ya existe') {
                setMessage('El usuario ya existe');
                form.setFields([
                    {
                        name: 'username',
                        errors: ['El usuario ya existe'],
                    },
                ]);
            } else {
                setMessage('No se pudo crear el profesor');
            }
            setIsModalVisible(true);
        } catch (error: any) {
            setIsModalVisible(true);
            setMessage(`Error: ${error.message}`);
        }
        setLoading(false);
    };

    const resetForm = () => {
        form.resetFields();
        form.setFieldsValue({
            status: 'Activo',
        });
    };

    useEffect(() => {
        resetForm();
    }, []);

    return (
        <MainLayout activeItem="users" breadcrumbItems={breadcrumbRoutes}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={14} xl={12}>
                        <Divider>Información del Usuario</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Ingresa el nombre' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="lastName" label="Apellido" rules={[{ required: true, message: 'Ingresa el apellido' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="status" label="Estado">
                                    <Select options={[
                                        { label: 'Activo', value: 'Activo' },
                                        { label: 'Inactivo', value: 'Inactivo' },
                                    ]} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="type" label="Tipo" rules={[{ required: true, message: 'Ingresa el tipo de usuario' }]}>
                                    <Select options={[
                                        { label: 'Administrador', value: 'administrador' },
                                        { label: 'General', value: 'general' },
                                    ]} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="username" label="Usuario" rules={[{ required: true, message: 'Ingresa el usuario' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="password" label="Clave" rules={[{ required: true, message: 'Ingresa la clave' }]}>
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Crear usuario
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                title="Resultado"
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsModalVisible(false)}>
                        OK
                    </Button>,
                ]}
            >
                <p>{message}</p>
            </Modal>
        </MainLayout>
    );
};

export default CreateUserPage;