import { apiCall } from './api';
import { IClass, IClassPayment } from './class';
import { ICourse } from './course';
import { IStudent } from './student';

export interface IPayment {
    _id: string;
    codigo: number;
    tipo: string;
    valor: number;
    fecha: Date;
    operacion: string;
}

interface IDiscount {
    tipo: string;
    valor: number;
}

export interface IPaymentSchema {
    _id: string;
    alumno: IStudent[];
    curso: ICourse[];
    clases: IClass[];
    institucion: string;
    tipo_clase: string;
    descripcion: string;
    total: number;
    descuento: IDiscount;
    pagos: IPayment[];
    fecha_registro: Date;
    matricula: boolean;
    debe: boolean;
    alerta_vencimiento: boolean;
}

export type IPaymentEdit = Omit<IPaymentSchema, '_id' | 'institucion' | 'fecha_registro' | 'alumno' | 'curso' | 'tipo_clase' | 'clases' | 'descuento'> & {
    descuento: IDiscount | null;
};

export type IPaymentCreate = Omit<IPaymentSchema, 'alumno' | 'curso' | 'clases'> & {
    alumno: string;
    curso: string;
    clases: string[];
};

export type IPaymentData = Omit<IPaymentSchema, 'alumno' | 'curso' | 'clases'> & {
    alumno: IStudent;
    curso: ICourse;
    clases: IClassPayment[];
};

interface IResponsePayment {
    message: string;
}

interface IResponsePayments {
    payments: IPaymentSchema[];
    total: number;
}

export const createPayment = async (paymentData: Omit<IPaymentCreate, '_id' | 'institucion' | 'fecha_registro'>) => {
    return await apiCall<IResponsePayment>({ url: '/payment', method: 'POST', data: paymentData });
};

export const getPayment = async (paymentId: string) => {
    return await apiCall<IPaymentData>({ url: `/payment/${paymentId}`, method: 'GET', data: null });
};

export const editPayment = async (paymentId: string, paymentData: IPaymentEdit) => {
    return await apiCall<IResponsePayment>({ url: `/payment/${paymentId}`, method: 'PUT', data: paymentData });
};

export const getPayments = async ({ search = '', limit = 20, offset = 0, classTypes = ['Escuela', 'Domicilio'] } = {}) => {
    return await apiCall<IResponsePayments>({ url: '/payment/list', method: 'POST', data: { search, limit, offset, classTypes } });
};

export const deletePayment = async (paymentId: string) => {
    return await apiCall<IResponsePayment>({ url: `/payment/${paymentId}`, method: 'DELETE', data: null });
};

export const transformPaymentsToTableData = (payments: IPaymentSchema[]) => {
    return payments.map(payment => {
        return {
            _id: payment._id,
            student: payment.alumno.map(a => `${a.nombre} ${a.apellido}`).join(', '),
            course: payment.curso.map(c => c.nombre).join(', '),
            type: payment.tipo_clase,
            total: `S/ ${payment.total}`,
            debt: payment.debe,
            payments: payment.pagos.map(p => `* S/ ${p.valor}`).join('\n'),
            classes: payment.clases,
            actions: {
                paymentId: payment._id
            }
        };
    });
};